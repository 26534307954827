import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GiftService } from 'src/app/_services/gift.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';

@Component({
  selector: 'app-addgift',
  templateUrl: './addgift.component.html',
  styleUrls: ['./addgift.component.css']
})
export class AddgiftComponent implements OnInit {


  submitted = false;
  giftForm: FormGroup;

  loading = false;
  loadingGift = false;
  ButtonText = "Save";

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts :ToastService,
    private giftService: GiftService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedGift();
  }

  get f() { return this.giftForm.controls; }

  private createForm() {
    this.giftForm = this.formBuilder.group({
      title: ['', Validators.required],
      arabicTitle: [''],
      description: [''],
      actualPrice: [0],
      displayPrice: [0],
      discountedPrice: [0],
      inStock : [true],
      displayOrder : [0],
      stockQty: [0],
      statusID: [true],
      giftID: 0,
      image: ['']
    });
  }

  private editForm(obj) {

    this.f.title.setValue(obj.title);
    this.f.arabicTitle.setValue(obj.arabicTitle);
    this.f.giftID.setValue(obj.giftID);
    this.f.actualPrice.setValue(obj.actualPrice);
    this.f.displayPrice.setValue(obj.displayPrice);
    this.f.discountedPrice.setValue(obj.discountedPrice);
    this.f.stockQty.setValue(obj.stockQty);
    this.f.image.setValue(obj.image);
    this.f.description.setValue(obj.description);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.imgComp.imageUrl = obj.image;
  }

  setSelectedGift() {    
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingGift = true;
        this.f.giftID.setValue(sid);
        this.giftService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingGift = false;
        });
      }
    })
  }

  onSubmit() {
    debugger
    this.giftForm.markAllAsTouched();
    this.submitted = true;
    if (this.giftForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.image.setValue(this.imgComp.imageUrl);

    if (parseInt(this.f.giftID.value) === 0) {
      //Insert gift
      this.giftService.insert(this.giftForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/gift']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update gift
      this.giftService.update(this.giftForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/gift']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
}
