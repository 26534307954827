import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { CustomersService } from 'src/app/_services/customers.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { Router } from '@angular/router';
import { Customers } from 'src/app/_models/Customers';
import { ToastService } from 'src/app/_services/toastservice';
import { ExcelService } from 'src/ExportExcel/excel.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  providers: [ExcelService]
})

export class CustomersComponent implements OnInit {
  data$: Observable<Customers[]> ;
  CustomerList: Customers[] = [];
  oldData: Customers[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;

  locationSubscription: Subscription;
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: CustomersService,
    public ls: LocalStorageService,
    public ts: ToastService,
    public router: Router,
    public excelService: ExcelService) {
    this.selectedBrand = this.ls.getSelectedUser().brandID;

    this.loading$ = service.loading$;
    this.submit = false;

  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.service.getAllData();
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  Edit(customers) {
    this.router.navigate(["admin/customer/edit", customers]);
  }

  Delete(obj) {
    if (confirm("Are you sure to delete? ")) {
      this.service.delete(obj).subscribe((res: any) => {
        if (res != 0) {
          this.ts.showSuccess("Success", "Record deleted successfully.")
          this.getData();
        }
        else
          this.ts.showError("Error", "Failed to delete record.")

      }, error => {
        this.ts.showError("Error", "Failed to delete record.")
      });
    }
  }

  
  ExportData() {
    
    this.service.getAllCustomer( )
      .subscribe((res: any) => {    
          this.CustomerList = res;  
          
    this.excelService.exportAsExcelFile(this.CustomerList, 'Customer_Export');     
      }, error => {
      });
  }
  exportAsXLSX(): void {
   this.ExportData();
  }
}
