import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ItemsService } from 'src/app/_services/items.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-additem',
  templateUrl: './additem.component.html',
  styleUrls: ['./additem.component.css']
})

export class AdditemsComponent implements OnInit {
  submitted = false;
  itemsForm: FormGroup;
  loading = false;
  loadingItems = false;
  Images = [];
  CategoryList = [];
  CityList = [];
  selectedCategoryIds: string[];
  selectedCityIds: string[];

  SubCategoryList = [];
  selectedSubCategoryIds: string[];

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public ts: ToastService,
    private itemsService: ItemsService
  ) {

    this.createForm();
    this.loadCategory();
    this.loadSubCategory();
    this.loadCity();
  }

  ngOnInit() {
    this.setSelecteditem();
  }

  get f() { return this.itemsForm.controls; }

  private createForm() {
    this.itemsForm = this.formBuilder.group({
      title: ['', Validators.required],
      arabicTitle: [''],
      description: [''],
      statusID: [true],
      isFeatured: [false],
      inStock: [false],
      displayOrder: [0],
      cost: [0],
      stockQty: [0],
      discountedPrice: [0],
      categoryID: [null],
      price: [0, Validators.required],
      itemID: 0,
      image: [''],
      sku: [''],
      barcode: [''],
      hoveredImage: [''],
      categories: [],
      cities: [],
      subcategories: [],
      color: [''],
      doublePrice: [0],
      file: [''],
      imagesSource: [''],
      itemImages: [],
      isDoubleQty: [false]
    });
  }
  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        debugger;
        var fileSize = event.target.files[i].size / 100000;
        if (fileSize > 5) { alert("Filesize exceed 500 KB"); }
        else {
          reader.onload = (event: any) => {
            console.log(event.target.result);
            this.Images.push(event.target.result);
            this.itemsForm.patchValue({
              imagesSource: this.Images
            });
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  private editForm(obj) {
    this.f.title.setValue(obj.title);
    this.f.arabicTitle.setValue(obj.arabicTitle);
    this.f.displayOrder.setValue(obj.displayOrder);
    this.f.price.setValue(obj.price);
    this.f.cost.setValue(obj.cost);
    this.f.discountedPrice.setValue(obj.discountedPrice);
    this.f.stockQty.setValue(obj.stockQty);
    this.f.itemID.setValue(obj.itemID);
    this.f.image.setValue(obj.image);
    this.f.color.setValue(obj.color);
    this.f.doublePrice.setValue(obj.doublePrice);
    this.f.hoveredImage.setValue(obj.image);
    this.f.description.setValue(obj.description);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.f.isFeatured.setValue(obj.isFeatured);
    this.f.isDoubleQty.setValue(obj.isDoubleQty);

    if (obj.categories != "") {
      var stringToConvert = obj.categories;
      this.selectedCategoryIds = stringToConvert.split(',').map(Number);
      this.f.categories.setValue(obj.categories);
    }

    if (obj.subcategories != "") {
      var stringToConvert = obj.subcategories;
      this.selectedSubCategoryIds = stringToConvert.split(',').map(Number);
      this.f.subcategories.setValue(obj.subcategories);
    }
    if (obj.cities != "") {
      var stringToConvert = obj.cities;
      this.selectedCityIds = stringToConvert.split(',').map(Number);
      this.f.cities.setValue(obj.cities);
    }
    // this.imgComp.imageUrl = obj.image;

    this.loadItemImages(this.f.itemID.value);
  }

  setSelecteditem() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingItems = true;
        this.f.itemID.setValue(sid);
        this.itemsService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingItems = false;
        });
      }
    })
  }

  onSubmit() {
    debugger
    this.itemsForm.markAllAsTouched();
    this.submitted = true;
    if (this.itemsForm.invalid) { return; }
    this.loading = true;
    this.f.categories.setValue(this.selectedCategoryIds == undefined ? "" : this.selectedCategoryIds.toString());
    this.f.subcategories.setValue(this.selectedSubCategoryIds == undefined ? "" : this.selectedSubCategoryIds.toString());
    this.f.cities.setValue(this.selectedCityIds == undefined ? "" : this.selectedCityIds.toString());
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    if (parseInt(this.f.itemID.value) === 0) {
      //Insert item
      this.itemsService.insert(this.itemsForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success", "Record added successfully.")
          this.router.navigate(['/admin/item']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error", "Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update item
      this.itemsService.update(this.itemsForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success", "Record updated successfully.")
          this.router.navigate(['/admin/item']);
        }
      }, error => {
        this.ts.showError("Error", "Failed to update record.")
        this.loading = false;
      });
    }
  }

  private loadCategory() {
    this.itemsService.loadCategories().subscribe((res: any) => {
      this.CategoryList = res;
    });
  }
  private loadCity() {
    this.itemsService.loadCities().subscribe((res: any) => {
      this.CityList = res;
    });
  }
  private loadItemImages(id) {
    this.itemsService.loadItemImages(id).subscribe((res: any) => {
      this.Images = res;
      this.f.imagesSource.setValue(this.Images);
    });
  }
  private loadSubCategory() {
    this.itemsService.loadSubCategories().subscribe((res: any) => {
      this.SubCategoryList = res;
    });
  }
  removeImage(obj) {
    const index = this.Images.indexOf(obj);
    this.Images.splice(index, 1);

    this.f.imagesSource.setValue(this.Images);
  }
}
