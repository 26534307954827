import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';
import { SettingService } from 'src/app/_services/settings.service';
import * as $ from 'jquery';
import 'bootstrap-colorpicker';
import { BgFeatureduploadComponent } from '../../../themeImages/bgfeaturedupload/bgfeaturedupload.component';
import { BgNewarrivaluploadComponent } from '../../../themeImages/bgnewarrivalupload/bgnewarrivalupload.component';
import { BgNewsletteruploadComponent } from '../../../themeImages/bgnewsletterupload/bgnewsletterupload.component';
import { BgPopularuploadComponent } from '../../../themeImages/bgpopularupload/bgpopularupload.component';
import { BgTestimonialuploadComponent } from '../../../themeImages/bgtestimonialupload/bgtestimonialupload.component';
import { BgWorkflowuploadComponent } from '../../../themeImages/bgworkflowupload/bgworkflowupload.component';

@Component({
  selector: 'app-dynamiccss',
  templateUrl: './dynamiccss.component.html'
})
export class DynamicCssComponent implements OnInit {

  submitted = false;
  dynamiccssForm: FormGroup;
  loading = false;
  loadingDynamicCss = false;
  ButtonText = "Save";
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public ts: ToastService,
    private service: SettingService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.setSelectedDynamicCss();
  }

  @ViewChild(BgFeatureduploadComponent, { static: true }) featuredimg;
  @ViewChild(BgNewarrivaluploadComponent, { static: true }) newarrivalimg;
  @ViewChild(BgNewsletteruploadComponent, { static: true }) newsletterimg;
  @ViewChild(BgPopularuploadComponent, { static: true }) popularprdimg;
  @ViewChild(BgTestimonialuploadComponent, { static: true }) testimonialimg;
  @ViewChild(BgWorkflowuploadComponent, { static: true }) workflowimg;

  get f() { return this.dynamiccssForm.controls; }

  private createForm() {
    this.dynamiccssForm = this.formBuilder.group({
      headerToparea: ['#c06dff'],
      bgWorkflow: [''],
      bgFeaturedProduct: [''],
      bgPopularProduct: [''],
      bgNewArrivals: [''],
      bgTestimonials: [''],
      bgNewsletter: [''],
      addButton: ['#c06dff'],
      dynamiccssID: 0
    });
  }

  private editForm(obj) {
    debugger
    this.f.dynamiccssID.setValue(obj.dynamicCssID);
    this.f.headerToparea.setValue(obj.headerToparea);
    this.f.bgWorkflow.setValue(obj.bgWorkflow);
    this.f.bgFeaturedProduct.setValue(obj.bgFeaturedProduct);
    this.f.bgPopularProduct.setValue(obj.bgPopularProduct);
    this.f.bgNewArrivals.setValue(obj.bgNewArrivals);
    this.f.bgTestimonials.setValue(obj.bgTestimonials);
    this.f.bgNewsletter.setValue(obj.bgNewsletter);
    this.f.addButton.setValue(obj.addButton);
    this.featuredimg.imageUrl = obj.bgFeaturedProduct;
    this.newarrivalimg.imageUrl = obj.bgNewArrivals;
    this.newsletterimg.imageUrl = obj.bgNewsletter;
    this.popularprdimg.imageUrl = obj.bgPopularProduct;
    this.testimonialimg.imageUrl = obj.bgTestimonials;
    this.workflowimg.imageUrl = obj.bgWorkflow;
  }

  setSelectedDynamicCss() {
    this.service.getCssById(0).subscribe(res => {
      this.editForm(res);
      this.loadingDynamicCss = false;
    });
  }

  onSubmit() {
    debugger
    this.dynamiccssForm.markAllAsTouched();
    this.submitted = true;
    if (this.dynamiccssForm.invalid) { return; }
    this.loading = true;
    this.f.bgWorkflow.setValue(this.workflowimg.imageUrl);
    this.f.bgFeaturedProduct.setValue(this.featuredimg.imageUrl);
    this.f.bgPopularProduct.setValue(this.popularprdimg.imageUrl);
    this.f.bgNewArrivals.setValue(this.newarrivalimg.imageUrl);
    this.f.bgTestimonials.setValue(this.testimonialimg.imageUrl);
    this.f.bgNewsletter.setValue(this.newsletterimg.imageUrl);
    this.service.updateCss(this.dynamiccssForm.value).subscribe(data => {
      this.loading = false;
      if (data != 0) {
        this.ts.showSuccess("Success", "Record updated successfully.")
        this.router.navigate(['/admin/dynamiccss']);
      }
    }, error => {
      this.ts.showError("Error", "Failed to update record.")
      this.loading = false;
    });
  }
}
