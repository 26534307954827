
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { Gift } from 'src/app/_models/Gift';
import { GiftService } from 'src/app/_services/gift.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/_services/toastservice';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  providers: []
})
export class GiftComponent implements OnInit {
  data$: Observable<Gift[]>;
  oldData: Gift[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  locationSubscription: Subscription;
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: GiftService,
    public ls: LocalStorageService,
    public ts: ToastService,
    public tss: ToastrService,
    public router: Router) {

    this.selectedBrand = this.ls.getSelectedUser().userID;
    this.loading$ = service.loading$;
    this.submit = false;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.service.getAllData();
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  Edit(gift) {
    this.router.navigate(["admin/gift/edit", gift]);
  }

  Delete(obj) {
    if (confirm("Are you sure to delete? ")) {
      this.service.delete(obj).subscribe((res: any) => {
        if (res != 0) {
          this.ts.showSuccess("Success", "Record deleted successfully.")
          this.getData();
        }
        else {
          this.ts.showError("Error", "Failed to delete record.");
        }
      }, error => {
        this.ts.showError("Error", "Failed to delete record.")
      });
    }
  }
  Deactive(id, rowVersion) {

    //   this.service.deactive(parseInt(id), rowVersion).subscribe((res: any) => {
    //     this.alertService.success("Gift has been Deactived");
    //     this.getBrandData();
    //   }, error => {
    //     this.alertService.error(error);
    //   });
  }
}
