import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '../_models/Location';


@Injectable({
  providedIn: 'root'
})

export class DashboadService {

  constructor(private http: HttpClient) {
  }

  public locations: Location[];

  GetDashboard( fdate,tdate) {
    
    // var fromdate = fdate;
    // var dd = String(fdate.getDate()).padStart(2, '0');
    // var mm = String(fdate.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = fdate.getFullYear();


    // var todate = tdate;
    // var dd1 = String(tdate.getDate()).padStart(2, '0');
    // var mm1 = String(tdate.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy1 = tdate.getFullYear();

    // fromdate = yyyy + '-' + dd + '-' + mm;  
    // todate = yyyy1 + '-' + dd1 + '-' + mm1;
    return this.http.get<any[]>(`api/dashboard/get/${fdate}/${tdate}`);
  }

}
