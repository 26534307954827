import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';
import { NotificationsService } from 'src/app/_services/notifications.service';

@Component({
  selector: 'app-addnotifications',
  templateUrl: './addnotifications.component.html',
  styleUrls: ['./addnotifications.component.css']
})
export class AddnotificationsComponent implements OnInit {

  submitted = false;
  notificationsForm: FormGroup;
  loading = false;
  loadingNotifications = false;
  ButtonText = "Save"; 
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private notificationsService: NotificationsService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedNotifications();
  }

  get f() { return this.notificationsForm.controls; }

  private createForm() {
    this.notificationsForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      statusID: [true],
      notificationID: 0,
      buttonURL: '',
      image: [''],
      startDate: [''],
      endDate: [''],
    
    });
  }

  private editForm(obj) {
    debugger
    this.f.title.setValue(obj.title);
    this.f.description.setValue(obj.description);
    this.f.notificationID.setValue(obj.notificationID);
    this.f.startDate.setValue(obj.startDate);
    this.f.buttonURL.setValue(obj.buttonURL);
    this.f.endDate.setValue(obj.endDate);
    this.f.image.setValue(obj.dealImage);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.imgComp.imageUrl = obj.dealImage;
  }

  setSelectedNotifications() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingNotifications = true;
        this.f.notificationID.setValue(sid);
        this.notificationsService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingNotifications = false;
        });
      }
    })
  }

  onSubmit() {
    this.notificationsForm.markAllAsTouched();
    this.submitted = true;
    if (this.notificationsForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.image.setValue(this.imgComp.imageUrl);

    this.f.startDate.setValue(this.parseDate(this._datepicker.fromDate));
    this.f.endDate.setValue(this.parseDate(this._datepicker.toDate));

    if (parseInt(this.f.notificationID.value) === 0) {
      //Insert notifications
      
      this.notificationsService.insert(this.notificationsForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/notifications']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });
    } else {
      //Update notifications
      this.notificationsService.update(this.notificationsForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/notifications']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }

  parseDate(obj) {
    return obj.year + "-" + obj.month + "-" + obj.day;;
  }
}
