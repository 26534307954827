import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { ToastService } from 'src/app/_services/toastservice';

@Component({
  selector: 'app-addlocation',
  templateUrl: './addlocation.component.html',
  styleUrls: ['./addlocation.component.css']
})
export class AddlocationComponent implements OnInit {
  submitted = false;
  locationForm: FormGroup;
  loading = false;
  loadingLocations = false;
  ButtonText = "Save"; 

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts :ToastService,
    private locationService: LocationsService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedLocations();
  }

  get f() { return this.locationForm.controls; }

  private createForm() {

    this.locationForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [''],
      address: [''],
      deliveryServices: [false],
      deliveryCharges: [0],
      minOrderAmount:[0],
      contactNo: [''],
      password: [''],
      currency: [''],
      latitude: [''],
      longitude: [''],
      description: [''],
      statusID: [true],
      locationID: 0,
      brandID: this.ls.getSelectedUser().brandID
    });
  }

  private editForm(obj) {
    debugger
    this.f.name.setValue(obj.name);
    this.f.email.setValue(obj.email);
    this.f.desc.setValue(obj.contactNo);
    this.f.contactNo.setValue(obj.contactNo);    
    this.f.minOrderAmount.setValue(obj.minOrderAmount);
    this.f.deliveryCharges.setValue(obj.deliveryCharges);
    this.f.address.setValue(obj.address);
    this.f.password.setValue(obj.password);
    this.f.currency.setValue(obj.currency);
    this.f.latitude.setValue(obj.latitude);
    this.f.longitude.setValue(obj.longitude);
    this.f.description.setValue(obj.description);
    this.f.locationID.setValue(obj.locationID);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
  }

  setSelectedLocations() {    
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingLocations = true;
        this.f.locationID.setValue(sid);
        this.locationService.getById(sid, this.f.brandID.value).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingLocations = false;
        });
      }
    })
  }

  onSubmit() {
    debugger
    this.locationForm.markAllAsTouched();
    this.submitted = true;

    if (this.locationForm.invalid) { return; }
    this.loading = true;
        this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    
    if (parseInt(this.f.locationID.value) === 0) {

      //Insert location
      this.locationService.insert(this.locationForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/location']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update location
      this.locationService.update(this.locationForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/location']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }



}
