import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';
import { SettingService } from 'src/app/_services/settings.service';

@Component({
  selector: 'app-generalsettings',
  templateUrl: './generalsettings.component.html',
  styleUrls: ['./generalsettings.component.css']
})
export class GeneralsettingsComponent implements OnInit {

  submitted = false;
  generalsettingsForm: FormGroup;
  loading = false;
  loadingGeneralsettings = false;
  ButtonText = "Save";
  openTime = { hour: new Date().getHours(), minute: new Date().getMinutes() };
  closeTime = { hour: new Date().getHours(), minute: new Date().getMinutes() };
  selectedSubCategoriesIds: string[];
  selectedLocationIds: string[];
  selectedgroupModifierIds: string[];
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public ts: ToastService,
    private generalsettingsService: SettingService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedGeneralsettings();
  }

  get f() { return this.generalsettingsForm.controls; }

  private createForm() {
    this.generalsettingsForm = this.formBuilder.group({
      taxPercentage: [0, Validators.required],
      deliveryCharges: [0, Validators.required],
      minimumOrderValue: [0, Validators.required],
      cod: [true],
      isDeliveryAllow: [true],
      isMaintenance: [false],
      // isOrderClosed: [false],
      payPal: [true],
      benefitPay: [true],
      credimax: [true],
      topHeaderText: [''],
      facebookUrl: [''],
      instagramUrl: [''],
      openTime: [''],
      closeTime: [''],
      twitterUrl: [''],
      shopUrl: [''],
      facebook: [true],
      instagram: [true],
      twitter: [true],
      settingID: 0
    });
  }

  private editForm(obj) {
    debugger
    this.f.settingID.setValue(obj.settingID);
    this.f.taxPercentage.setValue(obj.taxPercentage);
    this.f.deliveryCharges.setValue(obj.deliveryCharges);
    this.f.minimumOrderValue.setValue(obj.minimumOrderValue);
    this.f.cod.setValue(obj.cod == 1 ? true : false);
    this.f.isDeliveryAllow.setValue(obj.isDeliveryAllow == 1 ? true : false);
    this.f.isMaintenance.setValue(obj.isMaintenance == 1 ? true : false);
    // this.f.isOrderClosed.setValue(obj.isOrderClosed == 1 ? true : false);    
    this.f.benefitPay.setValue(obj.benefitPay == 1 ? true : false);
    this.f.openTime.setValue(obj.openTime);
    this.f.closeTime.setValue(obj.closeTime);
    this.f.credimax.setValue(obj.credimax == 1 ? true : false);
    this.f.payPal.setValue(obj.payPal == 1 ? true : false);
    this.f.topHeaderText.setValue(obj.topHeaderText);
    this.f.facebookUrl.setValue(obj.facebookUrl);
    this.f.instagramUrl.setValue(obj.instagramUrl);
    this.f.shopUrl.setValue(obj.shopUrl);
    this.f.twitterUrl.setValue(obj.twitterUrl);
    this.f.facebook.setValue(obj.facebook == 1 ? true : false);
    this.f.instagram.setValue(obj.instagram == 1 ? true : false);
    this.f.twitter.setValue(obj.twitter == 1 ? true : false);

    this.openTime = { hour: new Date("1/1/1900 " + obj.openTime).getHours(), minute: new Date("1/1/1900 " + obj.openTime).getMinutes() };
    this.closeTime = { hour: new Date("1/1/1900 " + obj.closeTime).getHours(), minute: new Date("1/1/1900 " + obj.closeTime).getMinutes() };
  }

  setSelectedGeneralsettings() {
    this.generalsettingsService.getById(0).subscribe(res => {
      //Set Forms
      this.editForm(res);
      this.loadingGeneralsettings = false;
    });
    // this.route.paramMap.subscribe(param => {
    //   const sid = +param.get('id');
    //   if (sid) {
    //     this.loadingGeneralsettings = true;
    //     this.f.offerID.setValue(sid);
    //     this.generalsettingsService.getById(sid).subscribe(res => {
    //       //Set Forms
    //       this.editForm(res);
    //       this.loadingGeneralsettings = false;
    //     });
    //   }
    // })
  }

  onSubmit() {
    debugger
    this.generalsettingsForm.markAllAsTouched();
    this.submitted = true;
    if (this.generalsettingsForm.invalid) { return; }
    this.loading = true;
    this.f.cod.setValue(this.f.cod.value == true ? 1 : 0);
    this.f.isDeliveryAllow.setValue(this.f.isDeliveryAllow.value == true ? 1 : 0);
    this.f.isMaintenance.setValue(this.f.isMaintenance.value == true ? 1 : 0);
    // this.f.isOrderClosed.setValue(this.f.isOrderClosed.value == true ? 1 : 0);
    this.f.benefitPay.setValue(this.f.benefitPay.value == true ? 1 : 0);
    this.f.credimax.setValue(this.f.credimax.value == true ? 1 : 0);
    this.f.payPal.setValue(this.f.payPal.value == true ? 1 : 0);

    this.f.facebook.setValue(this.f.facebook.value == true ? 1 : 0);
    this.f.instagram.setValue(this.f.instagram.value == true ? 1 : 0);
    this.f.twitter.setValue(this.f.twitter.value == true ? 1 : 0);
    this.f.openTime.setValue(this.openTime.hour + ":" + this.openTime.minute);
    this.f.closeTime.setValue(this.closeTime.hour + ":" + this.closeTime.minute);
     
    this.generalsettingsService.update(this.generalsettingsForm.value).subscribe(data => {
      this.loading = false;
      if (data != 0) {
        this.ts.showSuccess("Success", "Settings updated successfully.")
        this.router.navigate(['/admin/generalsettings']);
      }
    }, error => {
      this.ts.showError("Error", "Failed to update record.")
      this.loading = false;
    });

  }
}
