import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { CityService } from 'src/app/_services/city.service';

@Component({
  selector: 'app-addcity',
  templateUrl: './addcity.component.html',
  styleUrls: ['./addcity.component.css']
})
export class AddcityComponent implements OnInit {

  submitted = false;
  cityForm: FormGroup;
  loading = false;
  loadingCity = false;
  ButtonText = "Save";
  CategoryList = [];
  selectedCategoryIds: string[];
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts :ToastService,
    private cityService: CityService

  ) {
    this.createForm();
    this.loadCategory();
  }

  ngOnInit() {
    this.setSelectedSubcategory();
  }

  get f() { return this.cityForm.controls; }

  private createForm() {
    this.cityForm = this.formBuilder.group({
      cityID: [0],
      cityName: ['', Validators.required],      
      statusID: [true],
    
    });
  }

  private editForm(obj) {

    this.f.cityName.setValue(obj.cityName);        
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);

    // if (obj.categories != "") {
    //   var stringToConvert = obj.categories;
    //   this.selectedCategoryIds = stringToConvert.split(',').map(Number);
    //   this.f.categories.setValue(obj.categories);
    // }
  }

  setSelectedSubcategory() {    
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingCity = true;
        this.f.cityID.setValue(sid);
        this.cityService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingCity = false;
        });
      }
    })
  }

  onSubmit() {
    debugger
    this.cityForm.markAllAsTouched();
    this.submitted = true;
    if (this.cityForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    
    if (parseInt(this.f.cityID.value) === 0) {
      //Insert city
      this.cityService.insert(this.cityForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/city']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update city
      this.cityService.update(this.cityForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/city']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
  private loadCategory() {
    this.cityService.loadCategories().subscribe((res: any) => {
      this.CategoryList = res;
    });
  }
}
