import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubcategoryService } from 'src/app/_services/subcategory.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';

@Component({
  selector: 'app-addsubcategory',
  templateUrl: './addsubcategory.component.html',
  styleUrls: ['./addsubcategory.component.css']
})
export class AddsubcategoryComponent implements OnInit {

  submitted = false;
  subcategoryForm: FormGroup;
  loading = false;
  loadingSubcategory = false;
  ButtonText = "Save";
  CategoryList = [];
  selectedCategoryIds: string[];
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts :ToastService,
    private subcategoryService: SubcategoryService

  ) {
    this.createForm();
    this.loadCategory();
  }

  ngOnInit() {
    this.setSelectedSubcategory();
  }

  get f() { return this.subcategoryForm.controls; }

  private createForm() {
    this.subcategoryForm = this.formBuilder.group({
      title: ['', Validators.required],
      arabicTitle: [''],
      statusID: [true],
      subCategoryID: 0,
      image: [''],
      categories: [],
    });
  }

  private editForm(obj) {

    this.f.title.setValue(obj.title);
    this.f.arabicTitle.setValue(obj.arabicTitle);
    this.f.subCategoryID.setValue(obj.subCategoryID);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);

    if (obj.categories != "") {
      var stringToConvert = obj.categories;
      this.selectedCategoryIds = stringToConvert.split(',').map(Number);
      this.f.categories.setValue(obj.categories);
    }
  }

  setSelectedSubcategory() {    
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingSubcategory = true;
        this.f.subCategoryID.setValue(sid);
        this.subcategoryService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingSubcategory = false;
        });
      }
    })
  }

  onSubmit() {
    
    this.subcategoryForm.markAllAsTouched();
    this.submitted = true;
    if (this.subcategoryForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.categories.setValue(this.selectedCategoryIds == undefined ? "" : this.selectedCategoryIds.toString());
    if (parseInt(this.f.subCategoryID.value) === 0) {
      //Insert subcategory
      this.subcategoryService.insert(this.subcategoryForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/subcategory']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update subcategory
      this.subcategoryService.update(this.subcategoryForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/subcategory']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
  private loadCategory() {
    this.subcategoryService.loadCategories().subscribe((res: any) => {
      this.CategoryList = res;
    });
  }
}
