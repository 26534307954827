import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { CouponService } from 'src/app/_services/coupon.service';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';

@Component({
  selector: 'app-addcoupon',
  templateUrl: './addcoupon.component.html',
  styleUrls: ['./addcoupon.component.css']
})
export class AddcouponComponent implements OnInit {
   options: string[] = ["Amount", "Percent"];
  selectedQuantity = "Amount";
  submitted = false;
  couponForm: FormGroup;
  loading = false;
  loadingCoupon = false;
  ButtonText = "Save"; 
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private couponService: CouponService

  ) {
    this.createForm();
    this.f.type.setValue(this.selectedQuantity);
  }

  ngOnInit() {
    this.setSelectedCoupon();
  }

  get f() { return this.couponForm.controls; }

  private createForm() {
    this.couponForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      couponCode: [''],
      type: [''],
      statusID: [true],
      amount: 0,
      couponID: 0,
      fromDate: [''],
      toDate: ['']
    });
  }

  private editForm(obj) {
    
    this.f.title.setValue(obj.title);
    this.f.description.setValue(obj.description);
    this.f.couponCode.setValue(obj.couponCode);
    this.f.type.setValue(obj.type);
    this.f.amount.setValue(obj.amount);
    this.f.couponID.setValue(obj.couponID);
    this.f.fromDate.setValue(obj.fromDate);
    this.f.toDate.setValue(obj.toDate);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);

    this._datepicker.fromDate=new Date(obj.fromDate);
    this._datepicker.toDate=new Date(obj.toDate);
  }

  setSelectedCoupon() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingCoupon = true;
        this.f.couponID.setValue(sid);
        this.couponService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingCoupon = false;
        });
      }
    })
  }

  onSubmit() {
    this.couponForm.markAllAsTouched();
    this.submitted = true;
    if (this.couponForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.fromDate.setValue(this.parseDate(this._datepicker.fromDate));
    this.f.toDate.setValue(this.parseDate(this._datepicker.toDate));
debugger
    if (parseInt(this.f.couponID.value) === 0) {
      //Insert coupon
      this.couponService.insert(this.couponForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/coupon']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });
    } else {
      //Update coupon
      this.couponService.update(this.couponForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/coupon']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
  parseDate(obj) {
    return obj.year + "-" + obj.month + "-" + obj.day;;
  }
}
