import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { OffersService } from 'src/app/_services/offers.service';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';

@Component({
  selector: 'app-addoffers',
  templateUrl: './addoffers.component.html',
  styleUrls: ['./addoffers.component.css']
})
export class AddoffersComponent implements OnInit {

  submitted = false;
  offersForm: FormGroup;
  loading = false;
  loadingOffers = false;
  ButtonText = "Save"; 
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  Items = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private offersService: OffersService

  ) {
    this.createForm();
    this.loadItems();
  }

  ngOnInit() {
    this.setSelectedOffers();
  }

  get f() { return this.offersForm.controls; }

  private createForm() {
    this.offersForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      numberOfDays: [0],
      displayOrder: [1],
      statusID: [true],
      dealID: 0,
      itemID: 0,
      dealImage: [''],
      startDate: [''],
      endDate: [''],
    
    });
  }

  private editForm(obj) {
    debugger
    this.f.title.setValue(obj.title);
    this.f.description.setValue(obj.description);
    this.f.displayOrder.setValue(obj.displayOrder);
    this.f.numberOfDays.setValue(obj.numberOfDays);
    this.f.itemID.setValue(obj.itemID);
    this.f.dealID.setValue(obj.dealID);
    this.f.startDate.setValue(obj.startDate);
    this.f.endDate.setValue(obj.endDate);
    this.f.dealImage.setValue(obj.dealImage);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.imgComp.imageUrl = obj.dealImage;
  }

  setSelectedOffers() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingOffers = true;
        this.f.dealID.setValue(sid);
        this.offersService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingOffers = false;
        });
      }
    })
  }

  onSubmit() {
    this.offersForm.markAllAsTouched();
    this.submitted = true;
    if (this.offersForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.dealImage.setValue(this.imgComp.imageUrl);

    this.f.startDate.setValue(this.parseDate(this._datepicker.fromDate));
    this.f.endDate.setValue(this.parseDate(this._datepicker.toDate));

    if (parseInt(this.f.dealID.value) === 0) {
      //Insert offers
      
      this.offersService.insert(this.offersForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/offers']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });
    } else {
      //Update offers
      this.offersService.update(this.offersForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/offers']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }

  parseDate(obj) {
    return obj.year + "-" + obj.month + "-" + obj.day;;
  }
  private loadItems() {
    this.offersService.loadItems().subscribe((res: any) => {
      
      this.Items = res;
    });
  }
}
