export class Orders {
  customerID: number;
  orderNo: string;
  customerName: string;
  senderName: string;
  senderContact: string;
  amountTotal: number;
  tax: number;
  deliveryAmount: number;
  grandTotal: number;
  locationID: number;
  brandID: number;
  statusID: number;
  orderDate: string;
  orderType: string;
  orderID: number;
  paymentMethodID: number;
  paymentMethod: string;
}

export class OrderDetails {
  orderDetailID: number;
  orderID: number;
  title: string;
  itemID: number;
  quantity: number;
  price: number;
  cost: number;
  discountAmount: number;
  statusID: number;
  giftids: any;
  productNote: string;
  orderDetailGifts: OrderDetailGifts[];
}
export class OrderDetailGifts {

  orderDetailID: number;
  orderID: number;
  title: string;
  giftID: number;
  itemID: number;
  quantity: number;
  price: number;
  cost: number;
  statusID: number;
}
export class OrderDetailModifiers {
  OrderDetailModifierID: number;
  orderDetailID: number;
  orderID: number;
  ModifierID: number;
  quantity: number;
  price: number;
  cost: number;
  statusID: number;
}
export class OrderCheckout {
  paymentID: number;
  orderID: string;
  paymentModeID: string;
  amount: string;
  discountAmount: string;
}
export class CustomerOrders {
  customerOrderID: number;
  contactNo: string;
  customerName: string;
  email: string;
  description: string;
  address: string;
  nearestPlace: string;
  deliveryDate: string;
  deliveryTime: string;
  cardNotes: string;
  placeType: string;
  selectedTime: string;
  senderName: string;
  senderEmail: string;
  senderContact: string;
  senderAddress: string;

}